import 'twin.macro';
import React from 'react';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import { Heading, Paragraph } from '../components/atoms';
import MoonCalender from '../components/MoonCalender';

const HolidaysPage = () => (
  <Layout>
    <Seo title="Holidays" />
    <div tw="container mx-auto lg:my-24 my-16 grid">
      <div tw="grid">
        <div tw="mx-auto max-w-prose px-3">
          <div tw="text-center">
            <Heading type="h1">Holidays</Heading>
            <div tw="mt-3">
              <Paragraph>
                Full Moon and No Moon days are observed as holidays
              </Paragraph>
            </div>
          </div>
        </div>
        <MoonCalender />
      </div>
    </div>
  </Layout>
);

export default HolidaysPage;
